import React from 'react';
import './MediumListItem.css';

const MediumListItem = ({articleData}) => (
    <div className="CarouselCard MediumListItem">
        <div className="card custom-card flex-list-item">
            <div className="row">
                <div className="col-sm-8">
                    <div className="list-card-body">
                        <h5 className="card-title">
                            <a href={`${articleData.link}${process.env.REACT_APP_LOCAL_REF}`} className="custom-card-title" target="_blank" rel="noreferrer"
                               id={`Article_Title${articleData.title}`}> {articleData.title} </a>
                        </h5>

                        <div className="medium-tag-div">
                            <code>Tags: </code>{articleData.categories.map((item, index) => {
                            return (
                                <a href={`https://medium.com/tag/${item}`}
                                   target="_blank" key={index} rel="noreferrer">#{item}, </a>
                            )
                        })}
                        </div>
                    </div>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item custom-card-button-li">
                            <a href={`${articleData.link}${process.env.REACT_APP_LOCAL_REF}`}
                               className="read-more-link"
                               target="_blank" rel="noreferrer"
                               id={`Article_Btn${articleData.title}`}>Read more on Medium</a>
                        </li>
                    </ul>
                </div>
                <div className="col-sm-4">
                    <a href={`${articleData.link}${process.env.REACT_APP_LOCAL_REF}`} target="_blank" rel="noreferrer" id={`Article_Img${articleData.title}`}>
                        {/*<img src="img/placeholder.png" className="medium-card-image-div medium-card-img"/>*/}
                        <div className="list-card-image-div"
                             style={{backgroundImage: `url("${articleData.description.split("img src=\"")[1].toString().split(`" width`)[0]}")`}}>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
);

export default MediumListItem;
