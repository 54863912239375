import React, {useEffect, useState} from 'react';
import './MediumArticles.css';
import axios from "axios";
import MediumCard from "../MediumCard/MediumCard";
import MediumListItem from "../MediumListItem/MediumListItem";

const url = process.env.REACT_APP_MEDIUM_RSS_JSON;

function MediumArticles() {
    const [articleData, setArticleData] = useState(null);
    const [showGrid, setShowGrid] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(url); // Replace with your API endpoint
                setArticleData(response.data.items);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);


    const changeGridView = (show) => {
        setShowGrid(show)
    }

    return (
        <div className="MediumArticles" style={{minHeight: "85vh"}}>
            {/*<div>*/}
            {/*    <div>*/}
            {/*        <div className="PmResourcesComponent">*/}
            {/*            <div className="resume-content-div">*/}
            {/*                <MediumItemComponent articleDataArray={articleData}/>*/}
            {/*                <hr/>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className="btn-group view-button-group" role="group" aria-label="Basic example">
                <button type="button" className="btn btn-outline grid-button" onClick={() => changeGridView(true)}>
                    <i className="fas fa-th-large"></i>
                </button>
                <button type="button" className="btn btn-outline grid-button" onClick={() => changeGridView(false)}>
                    <i className="fas fa-th-list"></i>
                </button>
            </div>
            <div>
                {
                    articleData !== null ?
                        <div>
                            <div className="card-container">
                                {articleData.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            {showGrid === true ?
                                                <div className="grid-container">
                                                    <MediumCard articleData={item}/>
                                                </div> : <MediumListItem articleData={item}/>

                                                // <MediumCard articleData={item}/> :
                                                // <MediumListItem articleData={item}/>
                                            }
                                        </div>
                                    )
                                })}

                            </div>
                            {/*<div className="card-container">*/}
                            {/*    {articleData.map((item, index) => {*/}
                            {/*        return (*/}
                            {/*            <div key={index}>*/}
                            {/*                {showGrid === true ?*/}
                            {/*                    <MediumCard articleData={item}/> :*/}
                            {/*                    <MediumListItem articleData={item}/>*/}
                            {/*                }*/}
                            {/*            </div>*/}
                            {/*        )*/}
                            {/*    })}*/}
                            {/*</div>*/}
                        </div> : <div className="error-div">Error Fetching Data. Please visit the blog to read more</div>
                }
            </div>
            <div className="visit-div">
                <h5 className="visit-h5">visit <a href={process.env.REACT_APP_BLOG_URL} target="_blank"
                                                  rel="noreferrer">Nara's
                    Journal</a> to read more</h5>
            </div>
        </div>
    )
}

export default MediumArticles;
