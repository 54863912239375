import React from 'react';
import './CarouselCard.css';

const CarouselCard = ({articleData}) => (
    <div className="CarouselCard">
        <div className="card custom-card">
            <a href={articleData.url} target="_blank" rel="noreferrer" id={`Article_Img${articleData.title}`}>
                <div className="custom-card-image-div"
                     style={{backgroundImage: `url("${articleData.featured_image}")`}}>
                </div>
            </a>

            <div className="card-body custom-card-body">
                <h5 className="card-title">
                    <a href={articleData.url} className="custom-card-title" target="_blank" rel="noreferrer"
                       id={`Article_Title${articleData.title}`}>
                        {articleData.emoji} {articleData.title}
                    </a>
                </h5>
                <p className="card-text custom-card-text">{articleData.description.substring(0, 100)}..</p>
            </div>
            <ul className="list-group list-group-flush">
                <li className="list-group-item custom-card-button-li">
                    <a href={articleData.url}
                       className="btn btn-outline primary-button custom-card-button"
                       target="_blank" rel="noreferrer"
                       id={`Article_Btn${articleData.title}`}>{articleData.button_text}</a>
                </li>
            </ul>
        </div>
    </div>
);

export default CarouselCard;
