import React from 'react';
import './SummaryProjectComponent.css';

const SummaryProjectComponent = ({projects}) => {
    return (
        <div>
            <div className="procation">
                <h2 className="section-title">Projects</h2>
                <div className="container">
                    <div className="col-sm-12">
                        <div className="row">
                            {projects.map((pro, index) => (
                                <div className="col-sm-6 project-item-card" key={index}>
                                    <div>
                                        <div className="project-item-image-div">
                                            <h3 className="item-title">
                                                <b><a href={pro.url} target="_blank" rel="noreferrer">
                                                    {pro.name}
                                                </a></b>
                                                <span className="project-type">&nbsp;| {pro.highlight}</span>
                                            </h3>
                                            <div className="item-image-div">
                                                <a href={pro.url} target="_blank" rel="noreferrer">
                                                    <div className="custom-card-image-div" alt={pro.name}
                                                         style={{backgroundImage: `url("${pro.project_img}")`, backgroundColor: `#F9F9F8`}}>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="project-item-card-content">
                                            <ul className="item-description">
                                                {pro.responsibilities.map((responsibility, index) => (
                                                    <li key={index}>{responsibility}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default SummaryProjectComponent;
