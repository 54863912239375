import {Link} from "react-router-dom";
import React from "react";
import SEO from "../components/SEO";

function Page404({isDarkFromParent}) {
    if (isDarkFromParent === 'string') {
        isDarkFromParent = false;
    }

    return (
        <div>
            <div>
                <SEO
                    title={`Page Not Found - ${process.env.REACT_APP_OWNER}`}
                    name={process.env.REACT_APP_OWNER}
                    description={"Page Not Found"}
                    keywords={
                        "Kavindu Narathota, narathota, narathota.com, www.facebook.com/narathota, www.twitter.com/narathota, Engineer, Software, TekiMart, Entrepreneur, Designer, Sri Lankan, Freelancer, Mobile Development, Hybrid Mobile, Graphic Designer, Photoshop, Adobe, KRIYO, Angular, CodeGen, Senior Engineer, Software Engineer, Sri lanka, ScrumMaster, Agile, Canada, Toronto, North America, Software Product, Google," +
                        "Technical Product Manager, Quantum Mob Inc, Toronto, Canada, Product Strategies, Project Management, Strategic Partnerships, Customer Satisfaction, Agile Development, Jira Administrator, Cross-functional Collaboration, Software Engineering, Team Leadership, Enterprise Social Network, Angular 8+, Java (Spring-boot), Home Automation Systems, Entrepreneurship, Client Relationship Management, Marketing Strategy, Career Growth," +
                        "Software Consultant, Blog, PM, TPM, STPM"
                    }
                    author={`${process.env.REACT_APP_OWNER} | @narathota`}
                    image={"%PUBLIC_URL%/img/not-found-dark.svg"}
                />
            </div>
            <div style={{minHeight: "85vh"}}>
                <div className="container">
                    <div className="row" style={{padding: "10vh 5vh"}}>
                        <div className="container col-md-6 align-self-center">
                            {
                                !isDarkFromParent ?
                                    <img className="" src="/img/not-found-dark.svg" alt=""/> :
                                    <img className="" src="/img/not-found-light.svg" alt=""/>
                            }
                        </div>
                        <div className="container col-md-6 align-self-center">
                            <h1 className="page-404-title">404</h1>
                            <h2 className="page-404-sub-title">UH OH! You're lost.</h2>
                            <p className="page-404-body">The page you are looking for does not exist.
                                How you got here is a mystery. But you can click the button below
                                to go back to the homepage.
                            </p>
                            <Link className="btn primary-button" to={"/"}>HOME</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Page404;
