import React, {useEffect, useState} from 'react';
import './NavBar.css'
import {Link} from "react-router-dom";
import {BrowserView, MobileView} from 'react-device-detect';


function NavBar({updateParentTheme}) {
    const [isDark, setIsDark] = useState(typeof "boolean");
    let themeValueText = !isDark === true ? 'Switch to Dark mode' : 'Switch to Light mode';

    function changeTheme(res) {
        setIsDark(res)
        updateParentTheme(isDark);
    }

    function checkThemeOnload() {
        return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    }

    useEffect(() => {
        const theme = localStorage.getItem('theme');
        if (theme === null) {
            if (checkThemeOnload()) {
                changeTheme(true)
            } else {
                setIsDark(false)
            }
        } else {
            if (theme === 'dark') {
                changeTheme(true)
            } else {
                setIsDark(false)
            }
        }
    }, []);

    // sticky navbar
    const [navbarSticky, setNavbarSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 0) {
                setNavbarSticky(true);
            } else {
                setNavbarSticky(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    return (
        <nav className={`navbar navbar-custom ${navbarSticky ? "sticky" : ""}`}>
            <div className="container">
                <Link className="navbar-brand" to={"/"}>
                    <span>
                        {
                            !isDark ?
                                <img className="navbar-brand-img-custom" src={process.env.REACT_APP_LOGO} alt=""/> :
                                <img className="navbar-brand-img-custom" src={process.env.REACT_APP_LOGO_LIGHT} alt=""/>
                        }
                    </span>
                </Link>
                <div className="navbar-link-div">
                    <BrowserView className="nav-bar-browser-view">
                        <Link to={"feed"} className="navbar-link">Writing</Link>
                        <Link to={"pmresources"} className="navbar-link">PM Resources</Link>
                        {/*<Link to={"watch"} className="navbar-link">Video</Link>*/}
                        {/*<Link to={"about"} className="navbar-link">About</Link>*/}
                        {/*<Link to={"resume"} className="navbar-link">Resume</Link>*/}
                        {/*<Link to={"summary"} className="navbar-link">Summary</Link>*/}
                    </BrowserView>
                </div>
                <a href={process.env.REACT_APP_BLOG_URL} target="_blank" rel="noreferrer" className="navbar-link"
                   title="Medium | Nara's Journal">
                    <i className="fab fa-medium-m"></i></a>
                <a href={process.env.REACT_APP_LINKEDIN_URL} target="_blank" rel="noreferrer" className="navbar-link"
                   title="LinkedIn">
                    <i className="fab fa-linkedin-in"></i></a>
                <button className="btn btn-link theme-button" onClick={() => changeTheme(!isDark)}
                        title={themeValueText}>
                    {
                        !isDark ?
                            <span>🌙</span> : <span>☀️</span>
                    }
                </button>
                <MobileView className="nav-bar-mobile-view">
                    <div className="navbar-link-div-mobile">
                        <Link to={"feed"} className="navbar-link">Writing</Link>
                        <Link to={"pmresources"} className="navbar-link">Resources</Link>
                        {/*<Link to={"watch"} className="navbar-link">Video</Link>*/}
                        {/*<Link to={"about"} className="navbar-link">About</Link>*/}
                        {/*<Link to={"resume"} className="navbar-link">Resume</Link>*/}
                        {/*<Link to={"summary"} className="navbar-link">Summary</Link>*/}
                    </div>
                </MobileView>
            </div>
        </nav>
    );
}

export default NavBar;