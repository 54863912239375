import React from 'react';
import './ResumeExperiencesComponent.css';

const ResumeExperiencesComponent = ({experiences}) => {
    return (
        <div className="experience">
            <h2 className="section-title">Professional Experiences ({new Date().getFullYear() - 2011}+ years)</h2>
            <ul className="list-ul">
                {experiences.map((exp, index) => (
                    <li key={index} className="list-item">
                        {
                            exp.visibility ?
                                <div>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-sm-2 item-image-div">
                                                <a href={exp.employer_web} target="_blank" rel="noreferrer">
                                                    <img className="item-image" src={exp.employer_img}
                                                         alt={exp.employer}/>
                                                </a>
                                            </div>
                                            <div className="col-sm-10 item-content-div">
                                                <h3 className="item-title">{exp.title}</h3>
                                                <p className="item-organization">
                                                    <a href={exp.employer_web} target="_blank"
                                                       rel="noreferrer">{exp.employer}</a> - {exp.employer_location}</p>
                                                <p className="item-date">{exp.from_date} - {exp.to_date}</p>
                                                <ul className="item-description">
                                                    {exp.responsibilities.map((responsibility, indexTwo) => (
                                                        <li key={indexTwo}>{responsibility}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div> : <span/>
                        }
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ResumeExperiencesComponent;
