import React from 'react';
import {Helmet} from 'react-helmet';

export default function SEO({title, description, name, type, keywords, author, image}) {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name='description' content={description}/>
            <meta property="og:type" content={type}/>
            <meta property="og:title" content={title}/>
            <meta property="og:description" content={description}/>
            <meta property="og:image" content={image}/>
            <meta name="twitter:creator" content={name}/>
            <meta name="twitter:card" content={type}/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={description}/>
            <meta name="keywords" content={keywords}/>
            <meta name="author" content={author}/>
        </Helmet>
    )
}