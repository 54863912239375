import React from 'react';
import './ResumeLeadershipComponent.css';

const ResumeLeadershipComponent = ({leadership}) => {
    return (
        <div className="procation">
            <h2 className="section-title">Leadership & Community</h2>
            <ul className="list-ul">
                {leadership.map((ldr, index) => (
                    <li key={index} className="list-item">
                        {
                            ldr.visibility ?
                                <div>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-sm-2 item-image-div">
                                                <a href={ldr.organization_web} target="_blank" rel="noreferrer">
                                                    <img className="item-image" src={ldr.organization_img}
                                                         alt={ldr.organization}/>
                                                </a>
                                            </div>
                                            <div className="col-sm-10 item-content-div">
                                                <h3 className="item-title">
                                                    <a href={ldr.organization_web} target="_blank" rel="noreferrer">
                                                        {ldr.organization}
                                                    </a>
                                                </h3>
                                                <p className="item-date">{ldr.from_date} - {ldr.to_date}</p>
                                                <p className="item-organization">
                                                    {ldr.description}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div> : <span/>
                        }
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ResumeLeadershipComponent;
