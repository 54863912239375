import React from 'react';
import './MediumCard.css';

const MediumCard = ({articleData}) => (

    <div className="flex-card-item">
        <div className="CarouselCard">
            <div className="card custom-card">
                <a href={`${articleData.link}${process.env.REACT_APP_LOCAL_REF}`} target="_blank" rel="noreferrer"
                   id={`Article_Img${articleData.title}`}>
                    {/*<img src="img/placeholder.png" className="medium-card-image-div medium-card-img"/>*/}
                    <div className="medium-card-image-div"
                         style={{backgroundImage: `url("${articleData.description.split("img src=\"")[1].toString().split(`" width`)[0]}")`}}>
                    </div>
                </a>

                <div className="card-body custom-card-body">
                    <h5 className="card-title">
                        <a href={`${articleData.link}${process.env.REACT_APP_LOCAL_REF}`} className="custom-card-title" target="_blank" rel="noreferrer"
                           id={`Article_Title${articleData.title}`}> {articleData.title} </a>
                    </h5>

                    <div style={{}} className="medium-tag-div"><code>Tags: </code>
                        {articleData.categories.map((item, index) => {
                            return (
                                <a href={`https://medium.com/tag/${item}`}
                                   target="_blank" key={index} rel="noreferrer">#{item}, </a>
                            )
                        })}
                    </div>
                </div>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item custom-card-button-li">
                        <a href={`${articleData.link}${process.env.REACT_APP_LOCAL_REF}`}
                           className="read-more-link"
                           target="_blank" rel="noreferrer"
                           id={`Article_Btn${articleData.title}`}>Read more on Medium</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
);

export default MediumCard;
