import React from 'react';
import './Footer.css';

const Footer = () => (
    // <div className="fixed-bottom Footer">
    //     <p className="copyright">Kavindu Narathota © {new Date().getFullYear()}</p>
    // </div>

    <div className="footer-basic">
        <footer>
            <p className="copyright">{process.env.REACT_APP_OWNER} © {new Date().getFullYear()}</p>
        </footer>
    </div>
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
