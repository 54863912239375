import React from "react";
import MediumArticles from "../components/MediumArticles/MediumArticles";
import SEO from "../components/SEO";

const Experience = () => {
    return (
        <div>
            <div>
                <SEO
                    title={`Article Feed - ${process.env.REACT_APP_OWNER}`}
                    name={process.env.REACT_APP_OWNER}
                    description={"Senior Technical Product & Program Manager, Lead Software Engineer, Certified ScrumMaster® (CSM®)"}
                    keywords={
                        "Kavindu Narathota, narathota, narathota.com, www.facebook.com/narathota, www.twitter.com/narathota, Engineer, Software, TekiMart, Entrepreneur, Designer, Sri Lankan, Freelancer, Mobile Development, Hybrid Mobile, Graphic Designer, Photoshop, Adobe, KRIYO, Angular, CodeGen, Senior Engineer, Software Engineer, Sri lanka, ScrumMaster, Agile, Canada, Toronto, North America, Software Product, Google," +
                        "Technical Product Manager, Quantum Mob Inc, Toronto, Canada, Product Strategies, Project Management, Strategic Partnerships, Customer Satisfaction, Agile Development, Jira Administrator, Cross-functional Collaboration, Software Engineering, Team Leadership, Enterprise Social Network, Angular 8+, Java (Spring-boot), Home Automation Systems, Entrepreneurship, Client Relationship Management, Marketing Strategy, Career Growth," +
                        "Software Consultant, Blog, PM, TPM, STPM," +
                        "Medium articles, Article directory, Published articles, Writing platform, Content repository, Blog posts, Online articles, Article library, Reading recommendations, Curated content, Blogging platform, Author showcase, Thought leadership, Expert insights, Creative writing, Informative articles, Inspirational stories, Technology trends, Personal development, Business advice, Health and wellness, Travel experiences, Financial tips, Career guidance, Self-improvement, Lifestyle topics, Art and culture, Science and technology, Social issues, Entertainment news."
                    }
                    author={`${process.env.REACT_APP_OWNER} | @narathota`}
                    image={"%PUBLIC_URL%/img/avatar-2023.png"}
                />
            </div>
            <div className="container">
                <MediumArticles/>
            </div>
        </div>
    );
};

export default Experience;
