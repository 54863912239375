import React from "react";
import PMResourcesComponent from "../components/PMResourcesComponent/PMResourcesComponent";
import SEO from "../components/SEO";

const PMResources = () => {
    return (
        <div>
            <div>
                <SEO
                    title={`Project Management Resources - ${process.env.REACT_APP_OWNER}`}
                    name={process.env.REACT_APP_OWNER}
                    description={"Senior Technical Product & Program Manager, Lead Software Engineer, Certified ScrumMaster® (CSM®)"}
                    keywords={
                        "Kavindu Narathota, narathota, narathota.com, www.facebook.com/narathota, www.twitter.com/narathota, Engineer, Software, TekiMart, Entrepreneur, Designer, Sri Lankan, Freelancer, Mobile Development, Hybrid Mobile, Graphic Designer, Photoshop, Adobe, KRIYO, Angular, CodeGen, Senior Engineer, Software Engineer, Sri lanka, ScrumMaster, Agile, Canada, Toronto, North America, Software Product, Google," +
                        "Technical Product Manager, Quantum Mob Inc, Toronto, Canada, Product Strategies, Project Management, Strategic Partnerships, Customer Satisfaction, Agile Development, Jira Administrator, Cross-functional Collaboration, Software Engineering, Team Leadership, Enterprise Social Network, Angular 8+, Java (Spring-boot), Home Automation Systems, Entrepreneurship, Client Relationship Management, Marketing Strategy, Career Growth," +
                        "Software Consultant, Blog, PM, TPM, STPM, " +
                        "Project management resources, Project management templates, Project management tools, Project management guides, Project management best practices, Project management templates download, Project management templates free, Project management templates Excel, Project management templates PDF, Project management checklist, Project management templates for construction, Agile project management resources, Scrum project management resources, Project management software, Project management documents, Project management forms, Project management templates for small businesses, Project management templates for startups, Project management templates for IT projects, Project management templates for marketing projects, Project management templates for financial projects, Project management resources for beginners, Project management resources for professionals, Project management resources for remote teams, Project management resources for effective communication, Project management resources for time management, Project management resources for risk management, Project management resources for budgeting, Project management resources for stakeholder management, Project management resources for quality control."
                    }
                    author={`${process.env.REACT_APP_OWNER} | @narathota`}
                    image={"%PUBLIC_URL%/img/avatar-2023.png"}
                />
            </div>
            <div className="container">
                <div style={{minHeight: "85vh"}}>
                    <PMResourcesComponent/>
                </div>
            </div>
        </div>
    );
};

export default PMResources;
