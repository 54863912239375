import React from 'react';
import './ResumeEducationComponent.css';

const ResumeEducationComponent = ({education}) => {
    return (
        <div className="education">
            <h2 className="section-title">Education & Credentials</h2>
            <ul className="list-ul">
                {education.map((edu, index) => (
                    <li key={index} className="list-item">
                        {
                            edu.visibility ?
                                <div>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-sm-2 item-image-div">
                                                <a href={edu.institute_web} target="_blank" rel="noreferrer">
                                                    <img className="item-image" src={edu.institute_img}
                                                         alt={edu.institute}/>
                                                </a>
                                            </div>
                                            <div className="col-sm-10 item-content-div">
                                                <h3 className="item-title">
                                                    <a href={edu.credential_url} target="_blank" rel="noreferrer">
                                                        {edu.credential} - {edu.grade}
                                                    </a>
                                                </h3>
                                                <p className="item-organization">
                                                    <a href={edu.institute_web} target="_blank"
                                                       rel="noreferrer">{edu.institute}</a> - {edu.institute_location}
                                                </p>
                                                <p className="item-date">{edu.date}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> : <span/>
                        }
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ResumeEducationComponent;
