import React, {useEffect, useState} from 'react';
import {collection, onSnapshot, orderBy, query} from "firebase/firestore"
import './Header.css';
import {TypeAnimation} from 'react-type-animation';

import db from "../../firebase"

function Header() {
    const [aboutText, setAboutText] = useState([]);
    const [aboutMe, setAboutMe] = useState([]);
    const [socialLinks, setSocialLinks] = useState([]);
    const [credentialsList, setCredentialsList] = useState([]);

    useEffect(() => {
        const aboutTextQuery = query(collection(db, "abouttext"));
        const aboutMeQuery = query(collection(db, "aboutme"));
        const socialLinksQuery = query(collection(db, "sociallinks"), orderBy("order", "asc"));
        const credentialsListQuery = query(collection(db, "credentials"), orderBy("order", "asc"));

        const aboutTextUnsub = onSnapshot(aboutTextQuery, (querySnapshot) => {
            setAboutText(querySnapshot.docs.map(doc => doc.data().highlight));
        });

        const aboutMeUnsub = onSnapshot(aboutMeQuery, (querySnapshot) => {
            setAboutMe(querySnapshot.docs.map(doc => doc.data()));
        });

        const socialLinksUnsub = onSnapshot(socialLinksQuery, (querySnapshot) => {
            setSocialLinks(querySnapshot.docs.map(doc => doc.data()));
        });

        const credentialsListUnsub = onSnapshot(credentialsListQuery, (querySnapshot) => {
            setCredentialsList(querySnapshot.docs.map(doc => doc.data()));
        });

        return () => {
            aboutTextUnsub();
            aboutMeUnsub();
            socialLinksUnsub();
            credentialsListUnsub();
        };
    }, []);

    return (
        <header className="App-header">
            <div className="container">
                <div className="row">
                    <div className="col-sm-8">
                        <div className="container">
                            <div className="header-text-container">
                                <h2 className="header-name-h2">
                                    Hi, I'm
                                    <span className="header-name"> {process.env.REACT_APP_OWNER.split(' ')[0]}</span>
                                </h2>
                                <p className="header-title-p">
                                    {
                                        aboutText.length > 0 ?
                                            <TypeAnimation
                                                sequence={
                                                    [
                                                        'a Software Product Strategist', 1200,
                                                        'a Software Program Specialist', 1200,
                                                        'a Former Senior Software Engineer', 1200,
                                                        'a Certified ScrumMaster®', 1200
                                                    ]
                                                }
                                                //  Replacing previous Text
                                                wrapper="span"
                                                repeat={Infinity}
                                            /> : <span/>
                                    }
                                </p>
                            </div>
                        </div>

                        <div className="container">
                            {aboutMe.map((item, index) => {
                                return <p className="header-about-p" key={index}>{item.description} <a
                                    href="mailto:hello@narathota.com">hello@narathota.com</a></p>

                            })}
                        </div>

                        <div className="container">
                            <table>
                                <tbody>
                                <tr>
                                    {credentialsList.map((item, index) => {
                                        return <td width="50%" key={index}>
                                            <a href={item.cred_url} target="_blank" rel="noreferrer"
                                               id={`Credential${item.cred_name}`}>
                                                <img className="header-cert-img-custom" src={item.cred_img} alt=""/>
                                            </a>
                                        </td>

                                    })}
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="container">
                            <div className="header-social-div">
                                {/*<h5 className="header-social-title">Let's connect</h5>*/}
                                <ul className="social-share d-flex">
                                    {socialLinks.map((item, index) => {
                                        return (
                                            <span key={index}>
                                            {
                                                item.published ?
                                                    <li>
                                                        <a href={item.url} target="_blank" rel="noreferrer"
                                                           id={`SocialLinks${item.title}`}>
                                                            <i className={item.icon} title={item.title}/>
                                                        </a>
                                                    </li> : <span/>
                                            }
                                            </span>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="circle-container">
                            <div className="circle">
                                <img className="" src="/img/avatar-2023.png" alt=""/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
