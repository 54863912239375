import React, { useEffect, useState } from 'react';
import { collection, onSnapshot, orderBy, query } from "firebase/firestore"
import './HeaderCarousel.css';
import './CustomTabs.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CarouselCard from "../CarouselCard/CarouselCard";
// import 'react-tabs/style/react-tabs.css';
import db from "../../firebase"

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

function processArray(articlesArray, articleType) {
    let returnArray = [];
    for (let key in articlesArray) {
        if (articlesArray[key].type === articleType) {
            returnArray.push(articlesArray[key])
        }
    }
    return returnArray;
}

function HeaderCarousel() {
    const [articles, setArticles] = useState([]);
    const [articleTypes, setArticlesTypes] = useState([]);

    useEffect(() => {
        const articlesQuery = query(collection(db, "articles"), orderBy("order", "asc"));

        const articlesUnsub = onSnapshot(articlesQuery, (querySnapshot) => {
            setArticles(querySnapshot.docs.map(doc => doc.data()));
        });
        const articlesTypeUnsub = onSnapshot(articlesQuery, (querySnapshot) => {
            setArticlesTypes([...new Set(querySnapshot.docs.map(doc => doc.data().type))])
        });

        return () => {
            articlesUnsub();
            articlesTypeUnsub();
        };

    },
        [])

    return (
        <div className="HeaderCarousel">
            <div className="container">
                <div className="container">
                    <h4 className="tab-headers">Press Mentions</h4>

                </div>
                {
                    articles.length > 0 ?
                        <div>
                            <Carousel responsive={responsive}>
                                {processArray(articles, "Press").map((item, index2) => {
                                    return (
                                        <div key={index2}>
                                            {
                                                item.published ?
                                                    <CarouselCard articleData={item} /> : <span />
                                            }
                                        </div>
                                    )
                                })}
                            </Carousel>
                        </div> : <span />
                }
            </div>
            
            {/* Tabs view was removed on October 16, 2023 - check github branch 'new-header-carosel' for the old code */}

        </div>
    );
}

HeaderCarousel.propTypes = {};

HeaderCarousel.defaultProps = {};

export default HeaderCarousel;
