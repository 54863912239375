import React, {useEffect, useState} from 'react';
import {collection, onSnapshot, orderBy, query} from 'firebase/firestore';
import db from "../../firebase"
import './PMResourcesComponent.css';

import PMResourcesDocumentComponent from "../PMResourcesDocumentComponent/PMResourcesDocumentComponent";

const PmResourcesComponent = () => {
    const [pmResources, setPMResources] = useState([]);

    useEffect(() => {
        const pmResourcesQuery = query(collection(db, "pm_resources"), orderBy("order", "asc"));

        const pmResourcesUnsub = onSnapshot(pmResourcesQuery, (querySnapshot) => {
            setPMResources(querySnapshot.docs.map(doc => doc.data()));
        });

        return () => {
            pmResourcesUnsub();
        };
    }, []);
    return (
        <div>
            <div className="PmResourcesComponent">
                <div className="resume-content-div">
                    <PMResourcesDocumentComponent pmResources={pmResources}/>
                    <hr/>
                </div>
            </div>
        </div>
    );
};
export default PmResourcesComponent;
