import React from 'react';
import './SummaryContentComponent.css';

const SummaryContentComponent = ({content}) => {
    return (<div>
        <div className="procation">
            <h2 className="section-title">Professional Summary</h2>
            <div className="container summary-content-div">
                <p className="item-organization">
                    {content.summary}
                </p>
            </div>
        </div>
        <div className="procation">
            <h2 className="section-title">Key Success Areas</h2>
            <div className="container summary-content-div">
                <ul className="item-description">
                    {
                        content.key_success !== undefined ?
                            <div>
                                {content.key_success.map((responsibility, index) => (
                                    <li key={index}>{responsibility}</li>
                                ))}
                            </div> : <span/>
                    }
                </ul>
            </div>
        </div>
    </div>);
};
export default SummaryContentComponent;
