import React, {useState} from "react";
import {Navigate, Route, Routes} from "react-router-dom";

import './App.css';
import NavBar from "./components/NavBar/NavBar";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import HeaderCarousel from "./components/HeaderCarousel/HeaderCarousel";
import CoverLetter from "./page/CoverLetter";
import Page404 from "./page/Page404";
import Feed from "./page/Feed";
import HeaderBanner from "./components/HeaderBanner/HeaderBanner";
import Resume from "./page/Resume";
import Summary from "./page/Summary";
import PMResources from "./page/PMResources";
import SEO from "./components/SEO";
import ScrollToTopButtonComponent from "./components/ScrollToTopButtonComponent/ScrollToTopButtonComponent";

const Home = () => {
    return (
        <div>
            <div>
                <SEO
                    title={`Home - ${process.env.REACT_APP_OWNER}`}
                    name={process.env.REACT_APP_OWNER}
                    description={"Senior Technical Product & Program Manager, Lead Software Engineer, Certified ScrumMaster® (CSM®)"}
                    keywords={
                        "Kavindu Narathota, narathota, narathota.com, www.facebook.com/narathota, www.twitter.com/narathota, Engineer, Software, TekiMart, Entrepreneur, Designer, Sri Lankan, Freelancer, Mobile Development, Hybrid Mobile, Graphic Designer, Photoshop, Adobe, KRIYO, Angular, CodeGen, Senior Engineer, Software Engineer, Sri lanka, ScrumMaster, Agile, Canada, Toronto, North America, Software Product, Google," +
                        "Technical Product Manager, Quantum Mob Inc, Toronto, Canada, Product Strategies, Project Management, Strategic Partnerships, Customer Satisfaction, Agile Development, Jira Administrator, Cross-functional Collaboration, Software Engineering, Team Leadership, Enterprise Social Network, Angular 8+, Java (Spring-boot), Home Automation Systems, Entrepreneurship, Client Relationship Management, Marketing Strategy, Career Growth," +
                        "Software Consultant, Blog, PM, TPM, STPM"
                    }
                    author={`${process.env.REACT_APP_OWNER} | @narathota`}
                    image={"%PUBLIC_URL%/img/avatar-2023.png"}
                />
            </div>
            <div style={{minHeight: "85vh"}}>
                <Header/>
                {/*<hr className="div-hr"/>*/}
                {/*<div className="div-spacer"/>*/}
                <HeaderCarousel/>
            </div>
        </div>
    );
};

function App() {
    const [isDark, setIsDark] = useState(true);
    let themeValue = 'light';

    const changeTheme = (response) => {
        setIsDark(response);
        themeValue = isDark === true ? 'dark' : 'light';
        localStorage.setItem("theme", themeValue);
        document.documentElement.setAttribute("data-theme", themeValue);
    };

    return (
        <div className="App" id={themeValue}>
            <HeaderBanner/>
            <NavBar updateParentTheme={changeTheme}/>
            <div className="background-image">
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="cover" element={<CoverLetter/>}/>
                    <Route path="feed" element={<Feed/>}/>
                    {/*<Route path="about" element={<About/>}/>*/}
                    <Route path="resume" element={<Resume/>}/>
                    <Route path="summary" element={<Summary/>}/>
                    <Route path="pmresources" element={<PMResources/>}/>
                    <Route path="404" element={<Page404 isDarkFromParent={isDark}/>}/>
                    <Route path="/*" element={<Navigate to="404"/>}/>
                </Routes>
            </div>
            <ScrollToTopButtonComponent/>
            <Footer/>
        </div>
    );
}

export default App;
