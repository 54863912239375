import React from "react";
import CoverLetterComp from "../components/CoverLetterComp/CoverLetterComp";

const CoverLetter = () => {
    return (
        <div>
            <div className="container">
                <CoverLetterComp/>
            </div>
        </div>
    );
};

export default CoverLetter;
