import React, {useEffect, useState} from 'react';
import {collection, onSnapshot, orderBy, query} from 'firebase/firestore';
import './ResumeComponent.css';
import ResumeHeaderComponent from "../ResumeHeaderComponent/ResumeHeaderComponent";
import ResumeEducationComponent from "../ResumeEducationComponent/ResumeEducationComponent";
import ResumeKeyProjectsComponent from "../ResumeKeyProjectsComponent/ResumeKeyProjectsComponent";
import ResumeLeadershipComponent from "../ResumeLeadershipComponent/ResumeLeadershipComponent";
import ResumeExperiencesComponent from "../ResumeExperiencesComponent/ResumeExperiencesComponent";

import db from "../../firebase"

const ResumeComponent = () => {
    const [header, setHeader] = useState([]);
    const [experiences, setExperiences] = useState([]);
    const [education, setEducation] = useState([]);
    const [projects, setProjects] = useState([]);
    const [leadership, setLeadership] = useState([]);

    useEffect(() => {
        const headerQuery = query(collection(db, "personal"));
        const experiencesQuery = query(collection(db, "experiences"), orderBy("order", "desc"));
        const educationQuery = query(collection(db, "education"), orderBy("order", "desc"));
        const projectsQuery = query(collection(db, "projects"), orderBy("year", "desc"));
        const leadershipQuery = query(collection(db, "leadership"), orderBy("order", "desc"));

        const headerUnsub = onSnapshot(headerQuery, (querySnapshot) => {
            setHeader(querySnapshot.docs.map(doc => doc.data())[0]);
        });

        const experiencesUnsub = onSnapshot(experiencesQuery, (querySnapshot) => {
            setExperiences(querySnapshot.docs.map(doc => doc.data()));
        });

        const educationUnsub = onSnapshot(educationQuery, (querySnapshot) => {
            setEducation(querySnapshot.docs.map(doc => doc.data()));
        });

        const projectsUnsub = onSnapshot(projectsQuery, (querySnapshot) => {
            setProjects(querySnapshot.docs.map(doc => doc.data()));
        });

        const leadershipUnsub = onSnapshot(leadershipQuery, (querySnapshot) => {
            setLeadership(querySnapshot.docs.map(doc => doc.data()));
        });

        return () => {
            headerUnsub();
            experiencesUnsub();
            educationUnsub();
            projectsUnsub();
            leadershipUnsub();
        };
    }, []);
    return (
        <div>
            <div className="ResumeComponent">
                <div className="resume-download-div">
                    <a type="button" className="btn resume-download-button" href={process.env.REACT_APP_RESUME_LOCAL}
                       target="_blank">Download</a>
                </div>
                <div className="resume-content-div">
                    <ResumeHeaderComponent header={header}/>
                    <hr/>
                    <ResumeExperiencesComponent experiences={experiences}/>
                    <hr/>
                    <ResumeKeyProjectsComponent projects={projects}/>
                    <hr/>
                    <ResumeEducationComponent education={education}/>
                    <hr/>
                    <ResumeLeadershipComponent leadership={leadership}/>
                </div>
            </div>
        </div>
    );
};

export default ResumeComponent;
