import React, {useEffect, useState} from 'react';
import './CoverLetterComp.css';
import {FiCopy} from "react-icons/fi";

function CoverLetterComp() {
    const [company, setCompany] = useState("your company");
    const [content, setContent] = useState("");
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        mapCompany(company);
        document.getElementById("coverLetterInput").value = content;
    });

    function mapCompany(updatedCompany) {
        setContent(
            `Dear Hiring Manager,
            
I hope this letter finds you well.

As an experienced Technical Program & Product Manager with over to a decade of experience in the Software Engineering industry, I am excited to apply for the open position at "${updatedCompany}". Currently, I work as the Senior Manager, Technical Programs & Products at a leading software consultancy in Toronto, Canada, where I have been responsible for leading cross-functional teams, and client teams and managing the development of software products.

With my expertise in both engineering and product/program management, I am confident that my skills and experience make me a perfect fit for this role. In my current position, I have been leading and managing most of the programs, mentoring a group of product managers as their direct supervisor, and as the first hire to the department, I have been instrumental in forming the Product & Program Management Department from the ground up.

In addition to my professional commitments, I am also passionate about writing and have published original work on my personal blog (https://blog.narathota.com) as well as my current employer's website. In fact, some of my articles are among the top results in Google search.

As a permanent resident of Canada, I do not require any sponsorship for this employment now or in the future. Please find my resume attached for your reference. Thank you for considering my application.

Sincerely,
Kavindu Narathota`
        )
    }

    function changeCompany(event) {
        setCompany(event.target.value);
    }

    const copyToClipboard = async copyText => {
        try {
            await navigator.clipboard.writeText(copyText);
            setCopied(true);
            setTimeout(hideAlert, 1500);
        } catch (err) {
            setCopied(false);
        }
    };

    function hideAlert() {
        setCopied(false);
    }

    return (
        <div className="CoverLetterComp">
            {
                copied ?
                    <div className="alert alert-success custom-alert" role="alert">
                        <small>Copied</small>
                    </div> : <></>
            }
            <form>
                <div className="form-group">
                    <label htmlFor="companyInput" className="input-label">Company Name</label>
                    <input type="text" className="form-control input-field" id="companyInput"
                           aria-describedby="companyName"
                           placeholder="Enter Company Name" onChange={evt => changeCompany(evt)}/>
                </div>
                <br/>
                <div className="form-group">
                    <label htmlFor="coverLetterInput" className="input-label">Cover Letter</label>
                    <textarea className="form-control input-field" id="coverLetterInput" rows="21"></textarea>
                </div>
            </form>
            <button className="btn btn-outline-default float-end copy-button"
                    onClick={(e) => copyToClipboard(document.getElementById("coverLetterInput").value)}>
                <FiCopy/> <small>copy</small>
            </button>
        </div>
    )
}

CoverLetterComp.propTypes = {};

CoverLetterComp.defaultProps = {};

export default CoverLetterComp;
