import React from 'react';
import './ResumeKeyProjectsComponent.css';
import {Link} from "react-router-dom";

const ResumeKeyProjectsComponent = ({projects}) => {
    return (
        <div className="procation">
            <h2 className="section-title">Key Projects & Contributions
                <Link className="section-title-link" to={"/summary"}>&nbsp;(Read more: Professional Summary)</Link></h2>
            <ul className="list-ul">
                {projects.map((pro, index) => (
                    <li key={index} className="list-item">
                        {
                            pro.visibility ?
                                <div>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-sm-2 item-image-div">
                                                <a href={pro.url} target="_blank" rel="noreferrer">
                                                    <img className="item-image" src={pro.img} alt={pro.name}/>
                                                </a>
                                            </div>
                                            <div className="col-sm-10 item-content-div">
                                                <h3 className="item-title">
                                                    <a href={pro.url} target="_blank" rel="noreferrer">
                                                        {pro.name}
                                                    </a>
                                                </h3>
                                                <p className="item-organization">{pro.description}</p>

                                            </div>
                                        </div>
                                    </div>
                                </div> : <span/>
                        }
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ResumeKeyProjectsComponent;
