import React from 'react';
import './SummaryArticleComponent.css';
import CarouselCard from "../CarouselCard/CarouselCard";

const SummaryArticleComponent = ({articles, title}) => {
    return (
        <div className="procation">
            <h2 className="section-title">{title}</h2>
            <div className="container">
                <div className="col-sm-12">
                    <div className="row">
                        {articles.map((art, index) => (
                            <div key={index} className="col-sm-4 summary-article-card">
                                {
                                    art.summary_availability ?
                                        <div>
                                            <CarouselCard articleData={art}/>
                                        </div> : <span/>
                                }
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SummaryArticleComponent;
