import React, {useState} from 'react';
import './HeaderBanner.css';

function HeaderBanner() {
    const [showBanner, setShowBanner] = useState(typeof "boolean");

    function closeBanner(res) {
        // setShowBanner(res)
    }

    return (
        <div className="HeaderBanner">
            {
                showBanner ?
                    <div className="banner">
                        <div className="banner-content">
                            <div className="banner-text">
                                <a href={process.env.REACT_APP_BLOG_SUBSCRIBE_URL} target="_blank"
                                   onClick={() => closeBanner(!showBanner)}> Don't miss out on our latest updates - sign
                                    up for our newsletter today.</a>
                            </div>
                            {/*<AiOutlineCloseCircle className="banner-close-button"*/}
                            {/*                      onClick={() => closeBanner(!showBanner)}/>*/}
                        </div>
                    </div> : <span></span>
            }

        </div>
    )
}

HeaderBanner.propTypes = {};

HeaderBanner.defaultProps = {};

export default HeaderBanner;
