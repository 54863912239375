import React, {useEffect, useState} from 'react';
import {collection, onSnapshot, orderBy, query, where} from 'firebase/firestore';
import './SummaryComponent.css';

import db from "../../firebase"
import SummaryArticleComponent from "../SummaryArticleComponent/SummaryArticleComponent";
import SummaryContentComponent from "../SummaryContentComponent/SummaryContentComponent";
import SummaryProjectComponent from "../SummaryProjectComponent/SummaryProjectComponent";
import SpecialProjectsComponent from "../SpecialProjectsComponent/SpecialProjectsComponent";
import TestComponent from "../TestComponent/TestComponent";

const SummaryComponent = () => {
    const [articlesQM, setArticlesQM] = useState([]);
    const [articlesOther, setArticlesOther] = useState([]);
    const [professionalSummary, setProfessionalSummary] = useState([]);
    const [projects, setProjects] = useState([]);
    const [specialProjects, setSpecialProjects] = useState([]);

    useEffect(() => {
        const articlesQMQuery = query(collection(db, "articles"), where('summary_section', '==', 'qm'));
        const articlesOtherQuery = query(collection(db, "articles"), where('summary_section', '==', 'other'));
        const professionalSummaryQuery = query(collection(db, "professional_summary"));
        const projectsQuery = query(collection(db, "projects"), orderBy("year", "desc"));
        const specialProjectsQuery = query(collection(db, "special_projects"), orderBy("order", "asc"));

        const articlesQMUnsub = onSnapshot(articlesQMQuery, (querySnapshot) => {
            setArticlesQM(querySnapshot.docs.map(doc => doc.data()));
        });
        const articlesOtherUnsub = onSnapshot(articlesOtherQuery, (querySnapshot) => {
            setArticlesOther(querySnapshot.docs.map(doc => doc.data()));
        });
        const professionalSummaryUnsub = onSnapshot(professionalSummaryQuery, (querySnapshot) => {
            setProfessionalSummary(querySnapshot.docs.map(doc => doc.data())[0]);
        });
        const projectsUnsub = onSnapshot(projectsQuery, (querySnapshot) => {
            setProjects(querySnapshot.docs.map(doc => doc.data()));
        });
        const specialProjectsUnsub = onSnapshot(specialProjectsQuery, (querySnapshot) => {
            setSpecialProjects(querySnapshot.docs.map(doc => doc.data()));
        });

        return () => {
            articlesQMUnsub();
            articlesOtherUnsub();
            professionalSummaryUnsub();
            projectsUnsub();
            specialProjectsUnsub();
        };
    }, []);
    return (
        <div>
            <div className="ResumeComponent">
                <div className="resume-content-div">
                    <SummaryContentComponent content={professionalSummary}/>
                    <hr/>
                    <SummaryProjectComponent projects={projects}/>
                    <hr/>
                    <SummaryArticleComponent articles={articlesQM} title="Project Management Articles (QMO)"/>
                    <hr/>
                    <SummaryArticleComponent articles={articlesOther}
                                             title="Project Management Content (NARA’S JOURNAL)"/>
                    <hr/>
                    {/*<SpecialProjectsComponent specialProjects={specialProjects}/>*/}
                </div>
            </div>
        </div>
    );
};

export default SummaryComponent;
