import React from "react";
import SummaryComponent from "../components/SummaryComponent/SummaryComponent";
import SEO from "../components/SEO";

const Summary = () => {
    return (
        <div>
            <div>
                <SEO
                    title={`Professional Summary - ${process.env.REACT_APP_OWNER}`}
                    name={process.env.REACT_APP_OWNER}
                    description={"Senior Technical Product & Program Manager, Lead Software Engineer, Certified ScrumMaster® (CSM®)"}
                    keywords={
                        "Kavindu Narathota, narathota, narathota.com, www.facebook.com/narathota, www.twitter.com/narathota, Engineer, Software, TekiMart, Entrepreneur, Designer, Sri Lankan, Freelancer, Mobile Development, Hybrid Mobile, Graphic Designer, Photoshop, Adobe, KRIYO, Angular, CodeGen, Senior Engineer, Software Engineer, Sri lanka, ScrumMaster, Agile, Canada, Toronto, North America, Software Product, Google," +
                        "Technical Product Manager, Quantum Mob Inc, Toronto, Canada, Product Strategies, Project Management, Strategic Partnerships, Customer Satisfaction, Agile Development, Jira Administrator, Cross-functional Collaboration, Software Engineering, Team Leadership, Enterprise Social Network, Angular 8+, Java (Spring-boot), Home Automation Systems, Entrepreneurship, Client Relationship Management, Marketing Strategy, Career Growth," +
                        "Software Consultant, Blog, PM, TPM, STPM," +
                        "Senior Technical Product Manager, Quantum Mob Inc, Toronto, Canada, Project Management, Agile Development, Cross-functional Team Leadership, Strategic Planning, Customer Satisfaction, Technical Expertise, Jira Administration, Dashboard Creation, Metrics Analysis, Crisis Management, Team Management, Enterprise Software Design, Angular 8+, Java (Spring-boot), Workflow Design, Performance Analysis, Optimization, Home Automation Systems, Entrepreneurship, Client Relationship Management, Marketing Strategy, Product Launch, SPC+ (Student Price Card), Enterprise Software & System Audit, PayPower App, USBank - Apple Store (RewardOps), TribeCast, people's group, hy-tech drilling, student price card, spc, rewardops, loyaltyone, pc optimum, loblaw"
                    }
                    author={`${process.env.REACT_APP_OWNER} | @narathota`}
                    image={"%PUBLIC_URL%/img/avatar-2023.png"}
                />
            </div>
            <div className="container">
                <div style={{minHeight: "85vh"}}>
                    <SummaryComponent/>
                </div>
            </div>
        </div>
    );
};

export default Summary;
