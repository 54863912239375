import React from 'react';
import './ResumeHeaderComponent.css';

const ResumeHeaderComponent = ({header}) => {
    return (
        <div className="procation">
            <div className="header-container">
                <h1>{header.name}</h1>
                <h5>{header.header}</h5>
                <div className="container information-container">
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-sm-4"><a href={`mailto:${header.email}`}> {header.email}</a></div>
                            <div className="col-sm-4">{header.mobile}</div>
                            <div className="col-sm-4">{header.location}</div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-sm-6"><a href={header.website} target="_blank">{header.website}</a></div>
                            <div className="col-sm-6"><a href={header.linkedin} target="_blank">{header.linkedin}</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResumeHeaderComponent;
