// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getFirestore} from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDfsyB9k84qSbwWy7K598mSeGuHsKBenbU",
    authDomain: "narathota-web-v3.firebaseapp.com",
    projectId: "narathota-web-v3",
    storageBucket: "narathota-web-v3.appspot.com",
    messagingSenderId: "347363261885",
    appId: "1:347363261885:web:3f7245a6ac4e7320dba6d4",
    measurementId: "G-8DL4RNGBV1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const firestore = getFirestore();

export default firestore;
